.product__image {
  @include rem(margin-bottom, 15px);
}

.product__image--portrait {
  img {
    max-width: 85px;
  }
}

.product__image--landscape {
  img {
    max-width: 170px;
  }
}