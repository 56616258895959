/* Typo
*********************************************************/

.post {
    position: relative;
}

.post__title {
    @include rem(font-size, 40px);
    line-height: 1.1;
    color: $color-taube;
    font-family: Georgia, sans-serif;
    @include rem(margin-bottom, 10px);
    //@include hyphens(auto);
    text-overflow: ellipsis;
}

.post__title--sub {
    //@include rem(padding-top, 10px);
}

.post__lead {
    @include rem(font-size, 19px);
    @include rem(margin-bottom, 30px);

    p {
        @include rem(margin-bottom, 19px);

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        list-style-position: outside;
        list-style-type: disc;
        @include rem(margin-left, 16px);
        @include rem(margin-bottom, 19px);

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.post__subline {
    @include rem(font-size, 25px);
    line-height: 1.1;
    @include rem(margin-bottom, 10px);

    //@include hyphens(auto);
    .subline1 {
        font-family: Georgia, sans-serif;
        //opacity: 0.75;
    }

    .subline2 {
        //opacity: 0.75;
    }
}

.post__copy {
    @include rem(font-size, 19px);
    @include rem(margin-bottom, 30px);

    p {
        @include rem(margin-bottom, 19px);

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        list-style-position: outside;
        list-style-type: disc;
        @include rem(margin-left, 16px);
        @include rem(margin-bottom, 19px);

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.post__social {
    @include rem(margin-bottom, 40px);
}


.post__item--image {
    @include rem(margin-bottom, 19px);
}

.post__item--image--content {
    @include rem(margin-bottom, 30px);
}

@media only screen and (min-width: 600px) {
    .post__title {
        @include rem(font-size, 54px);
    }

    .post__lead {
        @include rem(font-size, 24px);
    }

    .post__subline {
        @include rem(font-size, 38px);
    }
}

@media only screen and (min-width: 800px) {
    .post__title {
        @include rem(font-size, $fontsize-headline);
        line-height: $lineheight-headline;
        @include rem(margin-bottom, 40px);
    }

    .post__lead {
        @include rem(font-size, $fontsize-lead);
        line-height: $lineheight-lead;
        letter-spacing: 1px;
        @include rem(margin-bottom, 65px);
    }

    .post__subline {
        @include rem(font-size, $fontsize-subline);
        line-height: $lineheight-subline;
        @include rem(margin-bottom, 25px);
    }

    .post__title--sub {
        @include rem(padding-top, 20px);
    }

    .post__copy {
        @include rem(font-size, $fontsize-copy);
        line-height: $lineheight-copy;
        @include rem(margin-bottom, 65px);
    }

    .post__social {
      margin-top: -40px;
    }

    .post__item--product {
      @include rem(margin-bottom, 75px);
    }

    .post__copy--margin {
        @include rem(margin-bottom, 80px);
    }

    .post__social {
        @include rem(margin-bottom, 60px);
    }

    /* Layout
    *********************************************************/

    .post__item--narrow {
        margin-left: calc($narrow-margin/$page-width)*100%;
    }
}