// Fonts Sizes

$fontsize-basic: 18px;
$lineheight-basic: 1.46;

$fontsize-utility: 13px;
$lineheight-utility: 1.46;

$fontsize-headline: 80px;
$lineheight-headline: 1;

$fontsize-lead: 23px;
$lineheight-lead: 1.46;

$fontsize-subline: 40px;
$lineheight-subline: 1;

$fontsize-copy: 19px;
$lineheight-copy: 1.46;

$fontsize-subnavigation: 34px;
$lineheight-subnavigation: 1.8;

$fontsize-mainnavigation: 20px;
$lineheight-mainnavigation: 1.46;


// Colors$page-width-halve

$color-black: #363640;
$color-taube: #a89b9d;
$color-blau: #363640;

$color-grey: #A89B9D;

$color-white: #ffffff;

// Layout

$gutter-px: 14;
$col-px: 68;

$narrow-width: ((8*$col-px)+(7*$gutter-px))*1px;
$narrow-margin: ((2*$col-px)+(2*$gutter-px))*1px;

$page-width: ((12*$col-px)+(11*$gutter-px))*1px;
$content-width: ((10*$col-px)+(9*$gutter-px)+20)*1px;
$wrap-width: ((12*$col-px)+(11*$gutter-px)+20)*1px;

$base-width: ((10*$col-px)+(9*$gutter-px))*1px;
$halve-width: ((5*$col-px)+(4*$gutter-px))*1px;

$single-item-width: ((3*$col-px)+(2*$gutter-px))*1px;
$single-item-full-width: ((4*$col-px)+(3*$gutter-px))*1px;
$single-item-margin: (1*$gutter-px)*1px;
