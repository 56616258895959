.section-nav {
    display: none;
}

@media only screen and (min-width: 940px) {
    .section-nav {
        display: block;
        @include rem(margin-bottom, 40px);
    }

    .section-nav__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .section-nav__item {
        @include rem (padding, 0 10px);
        @include rem(font-size, $fontsize-subnavigation);
        line-height: $lineheight-subnavigation;
        font-family: Georgia, sans-serif;
    }

    .section-nav__link {
        text-decoration: none;
        color: $color-blau;
        border-bottom: 3px solid transparent;
        transition: border-color 0.3s ease;
        &.current,
        &:hover {
            border-bottom: 3px solid $color-taube;
        }
    }
}
