.social-list {
    display: flex;
    //justify-content: center;
}

.social-list__link {
    display: block;
    width: 50px;
    height: 50px;
    //margin: 0 10px;
    margin-right: 20px;
    text-indent: -999px;
    overflow: hidden;
    background-size: 50px 50px;
}

.social-list__link--facebook {
    background-image: url("/theme/assets/dist/images/social-faceb.png");
}

.social-list__link--instagram {
    background-image: url("/theme/assets/dist/images/social-insta.png");
}