body {
  @include rem(font-size, $fontsize-basic);
  line-height: $lineheight-basic;
  color: $color-black;
  font-family: 'Effra-Light', sans-serif;
}

a {
  text-decoration: none;
  &:hover {
  }
}

.post__copy {
  a {
    color: $color-taube;
  }

  .button--copy {
      background-color: $color-blau;
      color: #f4f1ef;
      font-family: Georgia, sans-serif;
      padding: 5px 10px;
      text-decoration: none;

      &:hover {
        background-color: $color-taube;
      }
  }
}

.post__lead {
  a {
    color: $color-taube;
  }
}

.facebook {
    display: block;
    width: 25px;
    height: 25px;
    text-indent: -999px;
    overflow: hidden;
    background-image: url("/theme/assets/dist/images/facebook.png");
    background-size: 25px 25px;
}

.footer-facebook {
    display: inline-block;
    position: relative;
    width: 21px;
    height: 21px;
    text-indent: -999px;
    overflow: hidden;
    background-image: url("/theme/assets/dist/images/facebook.png");
    background-size: 21px 21px;
    top: 4px;
}

.footer-instagram {
    display: inline-block;
    position: relative;
    width: 21px;
    height: 21px;
    text-indent: -999px;
    overflow: hidden;
    background-image: url("/theme/assets/dist/images/instagram.png");
    background-size: 21px 21px;
    top: 4px;
}