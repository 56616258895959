.block__item--maps {
    @include rem(margin-bottom, 65px);
}

.gm_wrapper {
  position: relative;
  padding-bottom: 56%;
  height: 0;
  overflow: hidden;
  width: 100%;
}

#gmap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}