// Header

.site-header {
    width: 100%;
    @include rem(margin-bottom, 40px);
    position: relative;
    &.inner-wrap {
        max-width: 100%;
        padding: 0;
    }
    &.active {
        background-color: $color-blau;
        min-height: 100vh;
    }
}

.logo {
    margin: 20px auto;
    img {
        width: 100%;
    }
}

.logo-active {
    display: none;
}

.logo-passive {
    width: 50px;
    display: block;
}

.active {
    .logo-active {
        display: block;
        width: 260px;
    }
    .logo-passive {
        display: none;
    }
}

// Utility Navigation

.utility-nav {
    display: none;
    color: $color-taube;
    @include rem(font-size, 15px);
    font-family: Georgia, sans-serif;
    text-align: center;
}

.home .utility-nav,
.active .utility-nav {
    display: block;
    @include rem(margin, 20px 0);
}

.utility-nav__item {
    display: inline-block;
    &:after {
        content: " | ";
    }
    &:last-child {
        &:after {
            content: " ";
        }
    }
}

.utility-nav__link {
    color: $color-taube;
}

.utility-nav__link--lang {
    text-transform: uppercase;
}

@media only screen and (min-width: 940px) {

    .site-header {
        @include rem(margin-bottom, 60px);
        &.inner-wrap {
            max-width: $wrap-width;
            padding: 0 10px;
        }
        &.active {
            min-height: auto;
            background-color: #fff;
        }
    }

    .active {

        .logo-active {
            display: none;
        }
        .logo-passive {
            display: block;
        }
    }

    .utility-nav {
        display: block;
        position: absolute;
        top: 20px;
        right: 10px;
        margin: 0;
    }

}