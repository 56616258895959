.post__pressebilder {
    @include rem(margin-bottom, 30px);
}

@media only screen and (min-width: 800px) {
    .post__pressebilder {
        @include rem(margin-bottom, 65px);
    }
}

// Pressebilder

.post__pressebilder__list {

}

.post__pressebilder__item {
    display: inline-block;
    float: left;
    width: calc(200px/$narrow-width)*100%;
    margin-right: calc(21px/$narrow-width)*100%;
    &:nth-child(3n+3) {
        margin-right: 0;
    }
    @include rem(margin-bottom, 10px);
}

.post__pressebilder__link {

}

.post__pressebilder__link__image {
    display: block;
}

@media only screen and (min-width: 400px) {
    .post__pressebilder__item {
        @include rem(margin-bottom, 13px);
    }
}

@media only screen and (min-width: 450px) {
    .post__pressebilder__item {
        @include rem(margin-bottom, 15px);
    }
}

@media only screen and (min-width: 550px) {
    .post__pressebilder__item {
        @include rem(margin-bottom, 18px);
    }
}

@media only screen and (min-width: 600px) {
    .post__pressebilder__item {
        @include rem(margin-bottom, 21px);
    }
}

// Presseartikel

.post__presseartikel {
    @include rem(margin-bottom, 30px);
}

.post__presseartikel__list {

}

.post__presseartikel__item {
    @include rem(margin-bottom, 19px);

}

.post__presseartikel__link {
    color: $color-black;
    line-height: 1.2;
}

.post__presseartikel__link--titel {
    font-family: Georgia, sans-serif;
}

.post__presseartikel__link--publikation {
    text-decoration: underline;
}

@media only screen and (min-width: 800px) {
    .post__presseartikel {
        @include rem(margin-bottom, 65px);
    }
}