body {
  text-rendering: optimizeSpeed;
}

// Prevent Animation onLoad
.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

html {
    font-size: 62.5%;
}

body,
html {
    //height: 100%;
}

.page-wrapper {
    height: 100%;
}

.clearfix:before,
.clearfix:after {
     content: " "; /* 1 */
     display: table; /* 2 */
}
.clearfix:after {
     clear: both;
}
.clearfix {
     *zoom: 1;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

img {
    max-width: 100%;
    height: auto;
}
iframe {
    max-width: 100%;
}