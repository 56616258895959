/*
 * 'rem' is a Sass mixin that converts pixel values to rem values for whatever property is passed to it.
 * It returns two lines of code — one of the regular pixel values (for IE), and another with the
 * converted rem values (for everyone else). Special thanks to Chris Epstein (http://chriseppstein.github.com)
 * and Martin Bavio (http://martinbavio.com) for the help and code!
 *
 * Sample input:
 * .element {
 *   @include rem('padding',10px 0 2px 5px);
 * }
 *
 * Sample output:
 * .element {
 *   padding: 10px 0 2px 5px;
 *   padding: 1rem 0 0.2rem 0.5rem;
 * }
 *
 */

// Baseline, measured in pixels
// The value should be the same as the font-size value for the html element
// If the html element's font-size is set to 62.5% (of the browser's default font-size of 16px),
// then the variable below would be 10px.
$baseline_px: 10px;

@mixin rem($property, $px_values) {

    // Convert the baseline into rems
    $baseline_rem: calc($baseline_px / 1rem);

    // Print the first line in pixel values
    #{$property}: $px_values;

    // If there is only one (numeric) value, return the property/value line for it.
    @if type-of($px_values) == 'number' {
        #{$property}: calc($px_values / $baseline_rem);
    }

    // If there is more than one value, create a list and perform equations on each value
    @else {

        // Create an empty list that we can dump values into
        $rem_values: ();

        @each $value in $px_values {

            // If the value is zero, return 0
            @if $value == 0 {
                $rem_values: append($rem_values, $value);
            }

            // If the value is not zero, convert it from px to rem
            @else {
                $rem_values: append($rem_values, calc($value / $baseline_rem) );
            }

        }

        // Return the property and its list of converted values
        #{$property}: $rem_values;

    }

}

/* Transparent Colors
******************************************************/

@mixin transparent($color, $alpha) {
  $rgba: rgba($color, $alpha);
  $ie-hex-str: ie-hex-str($rgba);
  background-color: transparent;
  background-color: $rgba;
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#{$ie-hex-str},endColorstr=#{$ie-hex-str});
  zoom: 1;
}