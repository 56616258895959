.post__item--image {
  img {
    display: block;
  }
}
.post__item--image__image--left {
  @include rem(margin-bottom, 19px);
}

@media only screen and (min-width: 400px) {

  .post__item--image__image {
    float: left;
    width: calc($halve-width/$base-width)*100%;
  }

  .post__item--image__image--left {
    margin-right: calc(14px/$base-width)*100%;
    margin-bottom: 0;
  }
}