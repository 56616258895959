.warning {
    text-align: center;
    background-color: #dc3522;
    padding: 20px 0;
    margin-bottom: 20px;
    p {
        color: #fff;
        font-weight: 900;
    }
    a {
        text-decoration: underline;
        color: #fff;
        font-weight: 900;
    }
}