// Hauptnavigation
.site-nav {
    border-top: 1px solid $color-taube;
    display: none;
    &.active {
        display: block;
    }
}

.site-nav-prime__link,
.site-nav-sub__link {
    position: relative;
    display: block;
    color: $color-taube;
    @include rem(font-size, 30px);
    font-family: Georgia, sans-serif;
    @include rem(padding-left, 10px);
    line-height: 2;
    border-bottom: 1px solid $color-taube;
}

.site-nav-sub__link {
    @include rem(padding-left, 30px);
    @include rem(font-size, 21px);
}

.nav-toggle {
    position: absolute;
    width: 28px;
    height: 23px;
    top: 20px;
    right: 20px;
    background-image: url('/theme/assets/dist/images/nav-toggle.png');
    background-position: 0 0;
    background-size: 28px 46px;
    text-indent: -999px;
    overflow: hidden;
    &.active {
        background-position: 0 -23px;
    }
}

.site-nav-prime__item {
    position: relative;
}

.site-nav-sub__list {
    display: none;
    &.active {
        display: block;
    }
}

.submenu-button {
    cursor: pointer;
    position: absolute;
    z-index: 99;
    right: 0;
    top: 0;
    display: block;
    height: 60px;
    width: 100%;
    cursor: pointer;
    background-image: url('/theme/assets/dist/images/subnav-toggle.png');
    background-position: right -60px;
    background-size: 60px 120px;
    background-repeat: no-repeat;
    text-indent: -999px;
    overflow: hidden;
    &.active {
        background-position: right 0;
    }
}

@media only screen and (min-width: 940px) {

    .nav-toggle {
        display: none;
    }

    .submenu-button {
        display: none;
    }

    .site-nav {
        display: block;
        border-top: none;
        max-width: $wrap-width;
        padding: 0 10px;
        margin: 0 auto;
        @include rem(margin-bottom, 40px);
    }

    .site-nav-prime__list {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        position: relative;
    }

    .site-nav-prime__item {
        @include rem (padding, 0 10px);
    }

    .site-nav-prime__link {
        padding-left: 0;
        background-color: transparent;
        display: inline-block;
        text-decoration: none;
        color: $color-blau;
        @include rem(font-size, $fontsize-mainnavigation);
        line-height: $lineheight-mainnavigation;
        font-family: Georgia, sans-serif;
        border-bottom: 3px solid transparent;
        transition: border-color 0.3a ease;
        &.current,
        &:hover {
            border-bottom: 3px solid $color-taube;
        }
    }

    .site-nav-sub__list {
        &.active {
            display: none;
        }
    }
  }