.block__item--video {
    position: relative;
    padding-bottom: 56.25%;
    //padding-top: 30px;
    height: 0;
    overflow: hidden;
    @include rem(margin-bottom, 65px);
}

.block__item--video iframe,
.block__item--video object,
.block__item--video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}