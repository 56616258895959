.wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    height: 100%;
}

.inner-wrap {
    width: 100%;
    max-width: $wrap-width;
    padding: 0 10px;
    margin: 0 auto;
}

.content {
    width: 100%;
    max-width: $content-width;
    margin: 0 auto;
    padding: 0 10px;
    flex: 1 0 auto;
}