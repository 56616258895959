
.cookieconsent {
    width: 100%;
    box-sizing: border-box;
    padding: 18px;
    background: $color-taube;
    color: #FFF;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.cookieconsent p {
  font-size: 16px;
  margin-bottom: 1em;
}

.cookieconsent p a {
  color: #FFF;
        text-decoration: underline;
}

.cookieconsent.is-hidden {
    display: none;
}

.cookieconsent__btn {
    background: #FFF;
    color: #221F5E;
    font-size: 16px;
    text-decoration: none;
    padding: 10px 12px 12px 12px;
    display: inline-block;
    border: 1px solid #FFF;
}

.cookieconsent__btn:hover {
  color: #221F5E;
  text-decoration: none;
  opacity: 0.8;
  cursor: pointer;
}

.cookieconsent__btn--close {
    background: transparent;
    color: #FFF;
}

.cookieconsent__btn--close:hover {
  color: #FFF;
}

.cookieconsent__btn + .cookieconsent__btn {
    margin-left: 10px;
}

@media (min-width: 720px) {
    .cookieconsent p {
        margin-bottom: 0;
    }
    .cookieconsent__wrap {
        display: flex;
    }
    .cookieconsent__txt {
        width: calc(100% - 240px);
        box-sizing: border-box;
        padding-right: 18px;
    }
    .cookieconsent__btngroup {
        width: 240px;
        text-align: right;
    }
}