.site-footer {
    position: relative;
    text-align: center;
    font-size: 18px;
    font-family: Georgia, sans-serif;
    background-color: #353540;
    color: #b6abad;
    padding: 20px 10px;
    margin-top: 40px;
    a {
        color: #b6abad;
    }
}

.footer-adress {
    @include rem(margin-bottom, 24px);
}

.footer-adress__item {
    display: block;
}

.footer-link {

}

.back-to-top {
    display: block;
    width: 51px;
    height: 27px;
    background-image: url("/theme/assets/dist/images/top.png");
    background-size: 51px 27px;
    margin: 30px auto 0 auto;
    text-indent: -999px;
    overflow: hidden;
    display: none;
    &.show {
            display: block;
        }

}

@media only screen and (min-width: 800px) {
    .footer-adress {
        //@include rem(margin-bottom, 10px);
    }
    .footer-adress__item {
        display: inline;
        &:after {
            content: " | ";
        }
        &:last-child {
            &:after {
                content: "";
            }
        }
    }
}

@media only screen and (min-width: 1020px) {
    .footer-adress {
        //margin-bottom: 0;
    }
    .footer-link {
        //position: absolute;
        //right: 20px;
        //top: 20px;
    }
    .back-to-top {
        display: none;
        position: fixed;
        //right: -70px;
        //bottom: 10px;
        bottom: 135px;
        right: calc((100% - 826px) / 2 - 60px);
        &.show {
            display: block;
        }
    }
}