// Header
.logo-home {
    width: 300px;
    display: block;
    margin: 50px auto 0 auto;
}

// Content

.content.content-home {
    padding: 0;
}

.post-home {
    padding: 0 10px;
}

// Navigation

.home-nav {
    padding: 0 -10px;
    border-top: 1px solid $color-grey;
    @include rem(margin-bottom, 45px);
}

.home-nav__list {

}

.home-nav__item {
    position: relative;
}

.home-nav__link,
.home-nav-sub__link {
    position: relative;
    display: block;
    color: $color-black;
    @include rem(font-size, 30px);
    font-family: Georgia, sans-serif;
    @include rem(padding-left, 10px);
    line-height: 2;
    border-bottom: 1px solid $color-grey;
}

.home-nav-sub__link {
    @include rem(padding-left, 30px);
    @include rem(font-size, 21px);
}

.home-nav-sub__list {
    display: none;
    &.active {
        display: block;
    }
}

@media only screen and (min-width: 450px) {

}

@media only screen and (min-width: 550px) {

}

@media only screen and (min-width: 800px) {

    .home-nav {
        border-top: none;
        text-align: center;
    }



    .home-nav__link {
        display: inline;
        color: $color-blau;
        @include rem(font-size, 60px);
        line-height: 1.5;
        font-family: Georgia, sans-serif;
        border-bottom: 5px solid transparent;
        padding-bottom: 5px;
        padding-left: 0;
        //@include transition(border-color 0.3s ease);
        transition: border-color 0.3s ease;
        &:hover {
            border-bottom: 5px solid $color-taube;
        }
    }

    .home-nav-sub__list {
        &.active {
            display: none;
        }
}

}