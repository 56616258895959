.contact_form {
  textarea,
  input[type="text"],
  input[type="button"],
  input[type="number"],
  input[type="email"],
  input[type="submit"],
  button {
      -webkit-appearance: none;
      border-radius: 0;
      &:focus,
      &:active {
        -webkit-appearance: none;
        border-radius: 0;
      }
  }

  textarea:focus, input:focus{
    outline: none;
  }



}

.contact_form {
    &.sent {
        display: none;
    }
}

.confirmation {
  @include rem(font-size, 30px);
    line-height: 1.4;
    color: $color-taube;
    font-family: Georgia, sans-serif;
}

.post__form {
    @include rem(margin-bottom, 30px);
}

.control-label {
    display: block;
}

.form-control {
    border: none;
    background-color: #f4f1ef;
}

.my_form_error {
  .form-control {
    border: 1px solid red;
  }
}

.form-control {
    width: 100%;
    padding: 5px;
}

.form-group {
    @include rem(margin-bottom, 10px);
    position: relative;
    &.form__item--error {
        color: red;
        margin-bottom: 0;
    }
}



.form-control {
    display: block;
    width: 100%;
    padding: 4px;
}

.btn-primary {
    background-color: $color-blau;
    color: #f4f1ef;
    font-family: Georgia, sans-serif;
    float: right;
    border: none;
    @include rem(margin-top, 10px);
    padding: 5px 10px;
}

@media only screen and (min-width: 800px) {
    .post__form {
        @include rem(margin-bottom, 65px);
    }
}

.alert {
  @include rem(margin-bottom, 30px);

  ul {
    list-style-position: outside;
    list-style-type: disc;
    @include rem(margin-left, 16px);
  }
}

.alert-danger {
  color: red;
}