.landingpage {
    .section-nav {
        display: none;
    }

    .utility-nav__item--lang {
        display: none;
    }
}

@media only screen and (min-width: 800px) {
    .landingpage {
        .section-nav {
            display: none;
        }
    }
}